.page {    
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;

    text-shadow: 0px 0px 20px var(--black);

    background: var(--black);
    color: var(--white);
}

.page p {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
}

.page button {
    background-color: transparent;
    border-width: 0;
}

.spinner-row {
    justify-content: center;
    padding: 2rem 0 2rem;
}

.spinner-border {
    color: var(--light-grey);
}

a:hover {
    cursor: pointer;
}

.carousel-caption {
    transform: translateY(18%);

    background: linear-gradient(
        90deg, 
        transparent, 
        var(--black), 
        transparent);
    
    transition: all .5s;
}

.carousel-indicators {
    transform: translateY(20%);
}

.carousel-indicators button {
    background-color: var(--light-grey) !important;
    height: 8px !important;
    width: 8px !important;
    border-radius: 50% !important;
}

.carousel-img {
    border-radius: 100px;
    max-height: 75vh;

    transition: all .5s;
}

.carousel-img:hover {
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 40px var(--dark-grey));
}

.carousel-img:hover + .carousel-caption {
    text-shadow: 0px 0px 30px var(--light-grey);
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
    transition: all .5s;

    transform: scale(1.2);
    filter: drop-shadow(0px 0px 20px var(--light-grey));
}

.modal {
    color: var(--black);
}

.modal-header {
    width: 100%;
    text-align: center;
    background-size: cover;

    filter: grayscale(50%);
    box-shadow: inset 0 0 8rem 1rem var(--black);
}

.modal-title {
    width: 100%;
    font-family: Cormorant Infant;
    background: linear-gradient(90deg, transparent, var(--white), var(--white), transparent);
}

.btn-close:hover {
    transition: all .5s;

    transform: scale(1.2);
}

.modal-body {
    font-family: Quicksand;
    font-size: 18px;

    background: var(--white);
}

@media only screen and (max-width: 991px) {
    .carousel-caption {
        background: rgb(0, 10, 20, .5);
    }
}