.skills {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
}

.skills .form-control {
    color: var(--black);
}

.skills button {
    background-color: var(--dark-grey);
    color: var(--white);
    border: none;
    border-radius: 50%;

    transition: all 0.5s;
}

.skills button:hover {
    background-color: var(--grey);
    filter: drop-shadow(0px 0px 10px var(--dark-grey));
}

.skills button:active:focus {
    background-color: var(--light-grey);
    color: var(--dark-grey);
}

.skills table {
    color: var(--white);
    border-color: var(--grey);
}

.skills thead {
    font-size: 1.2rem;
}

.skills tbody {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
}

.skills-col {
    margin: 1rem 0rem;
}

.search-row {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    text-align: center;
}

.rating-active {
    color: var(--white);
    margin: 0rem .1rem;
}

.search-row .rating-active:hover {
    transition: all .5s;

    color: var(--light-grey);
    filter: drop-shadow(0px 0px 10px var(--dark-grey));
}

.rating-inactive {
    color: var(--dark-grey);
    margin: 0rem .1rem;
}

.search-row .rating-inactive:hover {
    transition: all .5s;
    
    color: var(--grey);
    filter: drop-shadow(0px 0px 10px var(--dark-grey));
}