.footer {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
    
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;

    color: var(--light-grey);
}

.footer-icon {
    padding: 0rem 1rem;
    transition: all .5s;

    color: var(--light-grey);
}

.footer-icon:hover {
    transform: scale(1.2);

    filter: drop-shadow(0px 0px 10px var(--light-grey));
}

@media only screen and (max-width: 640px) {
    .footer {
        font-size: 16px;
    }
}