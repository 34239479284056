.navbar {
    width: 100%;
    padding: .5rem 1rem;

    text-align: center;
}

.nav-wordmark {
    max-height: 80px;
    margin-left: 20%;
}

.navbar-collapse {
    margin-right: 2vw;
}

.navbar button {
    margin-right: 5%;
}

.main-nav {
    margin: 0 1rem;
    transition: all .5s;

    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-decoration: none;
    
    color: var(--white) !important;
}

.main-nav:hover {
    filter: drop-shadow(0px 0px 10px var(--light-grey));
}

.main-nav.active {
    filter: drop-shadow(0px 0px 10px var(--light-grey));
    font-style: italic;
}