@import url(https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap);
.navbar {
    width: 100%;
    padding: .5rem 1rem;

    text-align: center;
}

.nav-wordmark {
    max-height: 80px;
    margin-left: 20%;
}

.navbar-collapse {
    margin-right: 2vw;
}

.navbar button {
    margin-right: 5%;
}

.main-nav {
    margin: 0 1rem;
    transition: all .5s;

    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-decoration: none;
    
    color: var(--white) !important;
}

.main-nav:hover {
    filter: drop-shadow(0px 0px 10px var(--light-grey));
}

.main-nav.active {
    filter: drop-shadow(0px 0px 10px var(--light-grey));
    font-style: italic;
}
.footer {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
    
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;

    color: var(--light-grey);
}

.footer-icon {
    padding: 0rem 1rem;
    transition: all .5s;

    color: var(--light-grey);
}

.footer-icon:hover {
    transform: scale(1.2);

    filter: drop-shadow(0px 0px 10px var(--light-grey));
}

@media only screen and (max-width: 640px) {
    .footer {
        font-size: 16px;
    }
}
html, body {
    margin: 0px;
    padding: 0px;
    overflow-x: auto;
}

.home {
    text-align: center;
    height: 100vh;
    width: 100vw;

    background: linear-gradient(180deg, var(--dark-grey) 0%, var(--black) 90%);
}

.logo {
    padding: 2% 0;
    max-height: 80vh;
    max-width: 80vw;
}

.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.greeting-container {
    position: absolute;
    top: 50%;
    height: 18vw;
    overflow: hidden;

    padding: 0px 20px;
}

.greeting {
    display: block;
    animation: spin_words 5s infinite;
    margin: 2px 2px;

    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 12vw;

    color: var(--white);
    text-shadow: 0px 0px 8px var(--white);
}

@media only screen and (max-height: 800px) {
    img {
        max-height: 800px;
    }
}

@keyframes spin_words {
    20% {
        transform: translateY(-100%);
    }
    40% {
        transform: translateY(-200%);
    }
    60% {
        transform: translateY(-300%);
    }
    80% {
        transform: translateY(-400%);
    }
    100% {
        transform: translateY(400%);
    }
}
.page {    
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;

    text-shadow: 0px 0px 20px var(--black);

    background: var(--black);
    color: var(--white);
}

.page p {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
}

.page button {
    background-color: transparent;
    border-width: 0;
}

.spinner-row {
    justify-content: center;
    padding: 2rem 0 2rem;
}

.spinner-border {
    color: var(--light-grey);
}

a:hover {
    cursor: pointer;
}

.carousel-caption {
    transform: translateY(18%);

    background: linear-gradient(
        90deg, 
        transparent, 
        var(--black), 
        transparent);
    
    transition: all .5s;
}

.carousel-indicators {
    transform: translateY(20%);
}

.carousel-indicators button {
    background-color: var(--light-grey) !important;
    height: 8px !important;
    width: 8px !important;
    border-radius: 50% !important;
}

.carousel-img {
    border-radius: 100px;
    max-height: 75vh;

    transition: all .5s;
}

.carousel-img:hover {
    transform: scale(1.2);
    filter: drop-shadow(0px 0px 40px var(--dark-grey));
}

.carousel-img:hover + .carousel-caption {
    text-shadow: 0px 0px 30px var(--light-grey);
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
    transition: all .5s;

    transform: scale(1.2);
    filter: drop-shadow(0px 0px 20px var(--light-grey));
}

.modal {
    color: var(--black);
}

.modal-header {
    width: 100%;
    text-align: center;
    background-size: cover;

    filter: grayscale(50%);
    box-shadow: inset 0 0 8rem 1rem var(--black);
}

.modal-title {
    width: 100%;
    font-family: Cormorant Infant;
    background: linear-gradient(90deg, transparent, var(--white), var(--white), transparent);
}

.btn-close:hover {
    transition: all .5s;

    transform: scale(1.2);
}

.modal-body {
    font-family: Quicksand;
    font-size: 18px;

    background: var(--white);
}

@media only screen and (max-width: 991px) {
    .carousel-caption {
        background: rgb(0, 10, 20, .5);
    }
}
.skills {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
}

.skills .form-control {
    color: var(--black);
}

.skills button {
    background-color: var(--dark-grey);
    color: var(--white);
    border: none;
    border-radius: 50%;

    transition: all 0.5s;
}

.skills button:hover {
    background-color: var(--grey);
    filter: drop-shadow(0px 0px 10px var(--dark-grey));
}

.skills button:active:focus {
    background-color: var(--light-grey);
    color: var(--dark-grey);
}

.skills table {
    color: var(--white);
    border-color: var(--grey);
}

.skills thead {
    font-size: 1.2rem;
}

.skills tbody {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
}

.skills-col {
    margin: 1rem 0rem;
}

.search-row {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    text-align: center;
}

.rating-active {
    color: var(--white);
    margin: 0rem .1rem;
}

.search-row .rating-active:hover {
    transition: all .5s;

    color: var(--light-grey);
    filter: drop-shadow(0px 0px 10px var(--dark-grey));
}

.rating-inactive {
    color: var(--dark-grey);
    margin: 0rem .1rem;
}

.search-row .rating-inactive:hover {
    transition: all .5s;
    
    color: var(--grey);
    filter: drop-shadow(0px 0px 10px var(--dark-grey));
}
.card {
    margin: 2.4vw;
    background-color: var(--white) !important;
    border-color: var(--dark-grey) !important;
    color: var(--white);
    transition: all .5s;

    filter: grayscale(50%);

    text-align: center;
}

.card:hover {
    text-shadow: 0px 0px 10px var(--white);
    box-shadow: 0px 0px 2.5px 2.5px var(--light-grey);

    transform: scale(1.1);

    filter: none;
}

.card:hover .card-img-overlay {
    background-image: linear-gradient(
        0deg,
        transparent 40%,
        var(--black) 90%);
    
}

.card:hover .card-footer {
    color: var(--white) !important;
}

.card-link {
    text-decoration: none;
}

.card-header {
    background-color: var(--black) !important;
}

.card-img {
    border-radius: 2% 2% 0 0 !important;
}

.card-img-overlay {
    background-image: linear-gradient(
        0deg,
        transparent 20%,
        var(--black) 90%);
}

.card-footer {
    background-color: var(--black) !important;
    color: var(--light-grey) !important;
}

@media only screen and (max-width: 991px) {
    .card {
        margin: 4vw;
    }
}
.biodata-col {
    padding: 1rem;
}

.biodata-col img {
    max-height: 50vh;
    padding: 1rem;
}

.biodata-content {
    font-size: 120%;
    padding: 0 1rem;
    color: var(--white);
}

.biodata-row {
    justify-content: center;
    align-items: center;
}

.biodata-row .spinner-col {
    text-align: center;
}

.biodata-rowName {
    color: var(--light-grey);
}
.not-found {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;

    text-align: center;
    height: 100vh;
    padding-top: 10%;

    background: var(--black);
    color: var(--light-grey);
}

.not-found p {
    font-size: 4vh;
}

.not-found h1 {
    font-size: 8vh;
}

.not-found a {
    text-decoration: none;
    
    color: var(--white);

    transition: all .5s;
}

.not-found a:hover {
    font-size: 4.5vh;
    color: var(--white);
    text-shadow: 0px 0px 10px var(--light-grey);
}

.not-found-logo {
    max-height: 25vh;
}
:root {
    --black: #000A14;
    --dark-grey: #36404A;
    --grey: #76808A;
    --light-grey: #B5BFC9;
    --white: #EBF5FF;
}

@font-face {
    font-family: Pinyon Script, Garamond, serif;
    src: url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');
}

@font-face {
    font-family: Cormorant Infant, Garamond, serif;
    src: url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');
}

@font-face {
    font-family: Quicksand, Verdana, Geneva, Tahoma, sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');
}

html {
    background: var(--black);
}
