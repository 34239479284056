.biodata-col {
    padding: 1rem;
}

.biodata-col img {
    max-height: 50vh;
    padding: 1rem;
}

.biodata-content {
    font-size: 120%;
    padding: 0 1rem;
    color: var(--white);
}

.biodata-row {
    justify-content: center;
    align-items: center;
}

.biodata-row .spinner-col {
    text-align: center;
}

.biodata-rowName {
    color: var(--light-grey);
}