:root {
    --black: #000A14;
    --dark-grey: #36404A;
    --grey: #76808A;
    --light-grey: #B5BFC9;
    --white: #EBF5FF;
}

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');

@font-face {
    font-family: Pinyon Script, Garamond, serif;
    src: url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');
}

@font-face {
    font-family: Cormorant Infant, Garamond, serif;
    src: url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');
}

@font-face {
    font-family: Quicksand, Verdana, Geneva, Tahoma, sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@600&family=Pinyon+Script&family=Quicksand:wght@400;700&display=swap');
}

html {
    background: var(--black);
}