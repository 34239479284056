html, body {
    margin: 0px;
    padding: 0px;
    overflow-x: auto;
}

.home {
    text-align: center;
    height: 100vh;
    width: 100vw;

    background: linear-gradient(180deg, var(--dark-grey) 0%, var(--black) 90%);
}

.logo {
    padding: 2% 0;
    max-height: 80vh;
    max-width: 80vw;
}

.text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.greeting-container {
    position: absolute;
    top: 50%;
    height: 18vw;
    overflow: hidden;

    padding: 0px 20px;
}

.greeting {
    display: block;
    animation: spin_words 5s infinite;
    margin: 2px 2px;

    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 12vw;

    color: var(--white);
    text-shadow: 0px 0px 8px var(--white);
}

@media only screen and (max-height: 800px) {
    img {
        max-height: 800px;
    }
}

@keyframes spin_words {
    20% {
        transform: translateY(-100%);
    }
    40% {
        transform: translateY(-200%);
    }
    60% {
        transform: translateY(-300%);
    }
    80% {
        transform: translateY(-400%);
    }
    100% {
        transform: translateY(400%);
    }
}