.not-found {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;

    text-align: center;
    height: 100vh;
    padding-top: 10%;

    background: var(--black);
    color: var(--light-grey);
}

.not-found p {
    font-size: 4vh;
}

.not-found h1 {
    font-size: 8vh;
}

.not-found a {
    text-decoration: none;
    
    color: var(--white);

    transition: all .5s;
}

.not-found a:hover {
    font-size: 4.5vh;
    color: var(--white);
    text-shadow: 0px 0px 10px var(--light-grey);
}

.not-found-logo {
    max-height: 25vh;
}