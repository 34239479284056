.card {
    margin: 2.4vw;
    background-color: var(--white) !important;
    border-color: var(--dark-grey) !important;
    color: var(--white);
    transition: all .5s;

    filter: grayscale(50%);

    text-align: center;
}

.card:hover {
    text-shadow: 0px 0px 10px var(--white);
    box-shadow: 0px 0px 2.5px 2.5px var(--light-grey);

    transform: scale(1.1);

    filter: none;
}

.card:hover .card-img-overlay {
    background-image: linear-gradient(
        0deg,
        transparent 40%,
        var(--black) 90%);
    
}

.card:hover .card-footer {
    color: var(--white) !important;
}

.card-link {
    text-decoration: none;
}

.card-header {
    background-color: var(--black) !important;
}

.card-img {
    border-radius: 2% 2% 0 0 !important;
}

.card-img-overlay {
    background-image: linear-gradient(
        0deg,
        transparent 20%,
        var(--black) 90%);
}

.card-footer {
    background-color: var(--black) !important;
    color: var(--light-grey) !important;
}

@media only screen and (max-width: 991px) {
    .card {
        margin: 4vw;
    }
}